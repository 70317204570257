<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <div class="copyright d-flex flex-wrap">
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style>
</style>
